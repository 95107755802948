import React, { useEffect, useState, useRef } from 'react';
import './styles.scss';

const Drawer = ({ tabs }) => {
  const innerContentWrapper = useRef(null);
  //   const [drawOpen, setDrawerOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(null);

  useEffect(() => {
    if ($(innerContentWrapper.current).scrollTop() > 0) {
      innerContentWrapper.current.scrollTo(0, 0);
    }
  }, [selectedTab]);

  const handleTabClick = tabName => {
    if (selectedTab === tabName) {
      setSelectedTab(null);
    } else {
      setSelectedTab(tabName);
    }
  };

  return (
    <>
      <div id="drawer-wrapper">
        <div
          id="toggle"
          className={`card drawer-card pr-1 pb-1 pt-0 ${
            !selectedTab ? 'closed' : ''
          }`}
        >
          <div id="close-button-wrapper" className="position-relative w-100">
            <button
              id="drawer-close-button"
              tabindex="0"
              className="btn close position-absolute"
              data-dismiss="modal"
              aria-label="Close"
              // style="padding: 0.8rem;"
              onClick={() => {
                setSelectedTab(null);
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div
            ref={innerContentWrapper}
            id="card-inner-content"
            className="h-100 pr-2"
          >
            {tabs.map(tab => {
              if (tab?.name === selectedTab) {
                return (
                  <React.Fragment key={tab.name}>{tab.content}</React.Fragment>
                );
              }
            })}
          </div>
          <div id="tab-container">
            {tabs.map(tab => {
              const isActive = tab.name === selectedTab;
              return (
                <div
                  key={tab.name}
                  onClick={() => {
                    handleTabClick(tab.name);
                  }}
                  className={`${
                    isActive ? 'active' : ''
                  } tab mt-1 pl-0 font-weight-bold`}
                >
                  {tab.name}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Drawer;
// export default React.memo(Drawer);
