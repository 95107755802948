export const tabContent = [
  {
    title: 'Basic if, else, else if',
    href: 'basic-if-else',
    handlebarsCodeString: `
<!-- Template -->
{{#if guestFirstName }}
    <p>Hello, {{ guestFirstName }}!</p>
{{else if guestLastName }}
    <p>Hello, {{ guestLastName }} Group!</p>
{{else}}
    <p>Hello, group!</p>
{{/if}}
        `,
    jsonCodeString: `
// Test data one
{
"guestFirstName": "John",
"guestLastName": ""
}

// Test data two
{
"guestFirstName": "",
"guestLastName": "Smith"
}

// Test data three
{
"guestFirstName": "",
"guestLastName": ""
}
        `,
    htmlCodeString: `
<!-- Resulting HTML from test data one -->
<p>Hello, John!</p>

<!-- Resulting HTML from test data two -->
<p>Hello, Smith group!</p>

<!-- Resulting HTML from test data three -->
<p>Hello, group!</p>
        `,
  },
  {
    title: 'greaterThan',
    href: 'greater-than',
    handlebarsCodeString: `
<!-- Template -->
<p>
Hello.
{{#greaterThan daysUntilCheckIn 1}}
    We will see you in a couple days!
{{/greaterThan}}
    Let us know if you need anything.
</p>
`,
    jsonCodeString: `
// Test data one
{
    "daysUntilCheckIn": 2,
}

// Test data two
{
    "daysUntilCheckIn": 1,
}`,
    htmlCodeString: `
<!-- Resulting HTML from test data one-->
<p>Hello. We will see you in a couple days!. Let us know if you need anything.</p>

<!-- Resulting HTML from test data two-->
<p>Hello. Let us know if you need anything.</p>
`,
  },
  {
    title: 'lessThan',
    href: 'less-than',
    handlebarsCodeString: `
<!-- Template -->
<p>
Hello.
{{#lessThan daysUntilCheckIn 7}}
    We will see you this week!
{{/lessThan}}
    Let us know if you need anything.
</p>
`,
    jsonCodeString: `
// Test data one
{
    "daysUntilCheckIn": 5,
}

// Test data two
{
    "daysUntilCheckIn": 8,
}`,
    htmlCodeString: `
<!-- Resulting HTML from test data one-->
<p>Hello. We will see you this week! Let us know if you need anything.</p>

<!-- Resulting HTML from test data two-->
<p>Hello. Let us know if you need anything.</p>
`,
  },

  {
    title: 'equals',
    href: 'equals',
    handlebarsCodeString: `
<!-- Template -->
<p>
Hello.
{{#equals guestLastName 'Ferguson'}}
    We can't wait to see you and the rest of the Ferguson family.
{{/equals}}
    Have a great day!
</p>
`,
    jsonCodeString: `
// Test data one
{
    "guestLastName": 'Ferguson',
}

// Test data two
{
    "guestLastName": '',
}`,
    htmlCodeString: `
<!-- Resulting HTML from test data one-->
<p>Hello. We can't wait to see you and the rest of the Ferguson family. Have a great day!</p>

<!-- Resulting HTML from test data two-->
<p>Hello. Have a great day!</p>
`,
  },
  {
    title: 'notEquals',
    href: 'not-equals',
    handlebarsCodeString: `
<!-- Template -->
<p>
Hello {{guestFirstName}}.
{{#notEquals guestLastName 'Ferguson'}}
    The Ferguson family will not be joining us today.
{{/notEquals}}
    Have a great day!
</p>
`,
    jsonCodeString: `
// Test data one
{
    "guestLastName": 'John',
    "guestLastName": 'Smith',
}

// Test data two
{
    "guestLastName": 'Ben',
    "guestLastName": 'Ferguson',
}`,
    htmlCodeString: `
<!-- Resulting HTML from test data one-->
<p>Hello John. The Ferguson family will not be joining us today. Have a great day!</p>

<!-- Resulting HTML from test data two-->
<p>Hello Ben. Have a great day!</p>
`,
  },
  {
    title: 'and',
    href: 'and',
    handlebarsCodeString: `
<!-- Template -->
<p>
Hi there!
{{#and guestFirstName guestLastName }}
    Thank you for providing us with your full name.
{{/and}}
    Have a great day!
</p>
`,
    jsonCodeString: `
// Test data one
{
    "guestLastName": 'John',
    "guestLastName": 'Smith',
}

// Test data two
{
    "guestLastName": 'Ben',
    "guestLastName": '',
}`,
    htmlCodeString: `
<!-- Resulting HTML from test data one-->
<p>Hi there! Thank you for providing us with your full name. Have a great day!</p>

<!-- Resulting HTML from test data two-->
<p>Hi there! Have a great day!</p>
`,
  },
  {
    title: 'or',
    href: 'or',
    handlebarsCodeString: `
<!-- Template -->
<p>
Hi there!
{{#or propertyName propertyAddressDisplay }}
    Thank you for providing us with your property information.
{{/or}}
    Have a great day!
</p>
`,
    jsonCodeString: `
// Test data one
{
    "propertyName": 'Bella Vista',
    "propertyAddressDisplay": '',
}

// Test data two
{
    "propertyName": '',
    "propertyAddressDisplay": '',
}`,
    htmlCodeString: `
<!-- Resulting HTML from test data one-->
<p>Hi there! Thank you for providing us with your property information. Have a great day!</p>

<!-- Resulting HTML from test data two-->
<p>Hi there! Have a great day!</p>
`,
  },
];
