/*
export const createSuggestion = (
  instance,
  suggestion,
  hasBracket,
  hasParent = false
) => {
  const showSuggest = suggestion.children?.length;
  let insertBrackets = false;

  let insertText = suggestion.insertText;
  if (showSuggest) {
    insertText = `${suggestion.insertText}.`;
    insertBrackets = !hasParent;
  } else {
    if (hasParent) {
      insertText = `${suggestion.insertText}}} `;
    } else {
      insertText = `${suggestion.insertText}}} `;
      insertBrackets = true;
    }
  }

  if (insertBrackets) {
    const bracks = hasBracket ? '{' : '{{';
    insertText = `${bracks}${insertText}`;
  }

  return {
    label: suggestion.label,
    kind: instance.languages.CompletionItemKind.Variable,
    detail: suggestion.description,
    insertText,
    ...(showSuggest
      ? {
          command: {
            id: 'editor.action.triggerSuggest',
          },
        }
      : {}),
    range: null,
  };
};

export const buildSuggestions = (instance, suggestions, model, position) => {
  let results = [];
  let hasParent = false;

  const prevChar = model.getValueInRange({
    startColumn: position.column - 1,
    startLineNumber: position.lineNumber,
    endColumn: position.column,
    endLineNumber: position.lineNumber,
  });
  const hasBracket = prevChar === '{';

  const word = model.getWordUntilPosition(position);
  let text = word.word;

  const prev = model.findPreviousMatch(
    '{',
    {
      lineNumber: 0,
      column: 0,
    },
    false,
    false,
    null,
    true
  );

  if (prev) {
    text = model.getValueInRange({
      ...prev.range,
      endColumn: position.column,
    });
  }

  text = text.replace('{', '').trim();

  if (text.length === 0) {
    results = suggestions;
  } else {
    const splits = text.split('.').filter(s => s.trim() !== '');

    // fo => handled below...
    // foo.ba => foo.children.includes(split)
    // foo.bar. => bar.children
    // foo.bar.b => bar.children.includes(split)
    // foo.bar.baz => []
    if (splits.length) {
      hasParent = true;

      let i = 0;
      let children = suggestions;
      const dotEnd = text[text.length - 1] === '.';

      for (const split of splits) {
        const found = children.find(c => c.insertText === split);
        const isLast = splits.length - 1 === i++;

        if (dotEnd) {
          if (found?.children?.length) {
            children = found.children;

            if (isLast) {
              results = children;
            }
          }
        } else {
          if (found) {
            if (isLast) {
              children = children.filter(s => s.insertText.includes(text));
            } else if (found.children) {
              children = found.children;
            }
          }
        }
      }

      results = children;
    } else {
      results = suggestions.filter(s => s.insertText.includes(text));
    }
  }

  return results.map(s => createSuggestion(instance, s, hasBracket, hasParent));
};

*/

// HERE IS MY REFACTORED EXAMPLE:

export const createSuggestion = (
  instance,
  suggestion,
  hasPrevBracket,
  hasNextBracket,
  hasParent = false,
  prevBracketNumber,
  nextBracketNumber
) => {
  const hasHash = suggestion?.label && suggestion?.label.indexOf('#') !== -1;

  const showSuggest = suggestion.children?.length;
  let insertBrackets = false;

  let insertText = suggestion.insertText;
  if (showSuggest) {
    insertText = `${suggestion.insertText}.`;
    insertBrackets = !hasParent;
  } else {
    if (hasParent) {
      insertText = `${suggestion.insertText}`;
    } else {
      insertText = `${suggestion.insertText}`;
      insertBrackets = true;
    }
  }
  const getNextBracketsFromPreviousBrackets = () => {
    switch (prevBracketNumber) {
      case 1:
        return '}}';
      // case 2:
      //   return '}}';
      case 3:
        return '}}}';
      default:
        return '}}';
    }
  };

  const getPrevBrackets = bracketNumber => {
    switch (bracketNumber) {
      case 1:
        return '{';
      case 2:
        return '';
      case 3:
        return '';
      case 4:
        return '{{';
      case 0:
        return '';
    }
  };
  const getNextBrackets = bracketNumber => {
    switch (bracketNumber) {
      case 1:
        return '}';
      case 2:
        return '';
      case 3:
        return '';
      case 4:
        return '}}';
      case 0:
        return getNextBracketsFromPreviousBrackets();
    }
  };

  const getPrevLoopBrackets = bracketNumber => {
    switch (bracketNumber) {
      case 1:
        return '{{';
      case 2:
        return '{{';
      default:
        return '{{';
    }
  };

  const getNextLoopBrackets = bracketNumber => {
    switch (bracketNumber) {
      case 1:
        return '}}';
      case 2:
        return '}}';
      case 0:
        return getNextBracketsFromPreviousBrackets();
      default:
        return '}}';
    }
  };

  if (hasHash) {
    const brack = getNextLoopBrackets(nextBracketNumber);

    insertText = `${getPrevBrackets(
      prevBracketNumber
    )}${insertText}${getNextLoopBrackets(nextBracketNumber)}

${getPrevLoopBrackets(prevBracketNumber)}/${insertText.slice(
      1
    )}${getNextBrackets(nextBracketNumber)}`;
  } else {
    insertText = `${getPrevBrackets(
      prevBracketNumber
    )}${insertText}${getNextBrackets(nextBracketNumber)}`;
  }

  // }

  return {
    // label: suggestion.label,
    // kind: instance.languages.CompletionItemKind.Variable,
    // detail: suggestion.description,
    // suggestion: null,
    ...suggestion,
    insertText,
    ...(showSuggest
      ? {
          command: {
            id: 'editor.action.triggerSuggest',
          },
        }
      : {}),
    range: null,
  };
};

export const buildSuggestions = (
  instance,
  suggestions,
  model,
  position,
  containsTripleBraces
) => {
  let results = [];
  let hasParent = false;

  const getPreviousBracketNumber = () => {
    const bracketOptions = {
      startLineNumber: position.lineNumber,
      endColumn: position.column,
      endLineNumber: position.lineNumber,
    };
    const firstValue = model.getValueInRange({
      startColumn: position.column - 1,
      ...bracketOptions,
    });
    const secondValue = model.getValueInRange({
      startColumn: position.column - 2,
      ...bracketOptions,
    });

    const thirdValue = model.getValueInRange({
      startColumn: position.column - 3,
      ...bracketOptions,
    });

    const hasThreeBrackets =
      firstValue === '{' && secondValue === '{{' && thirdValue === '{{{';
    if (hasThreeBrackets) return 3;

    const hasTwoBrackets = firstValue === '{' && secondValue === '{{';
    if (hasTwoBrackets) return 2;
    if (firstValue === '{') return 1;
    return 0;
  };
  const getNextBracketNumber = () => {
    const bracketOptions = {
      startColumn: position.column,
      startLineNumber: position.lineNumber,
      endLineNumber: position.lineNumber,
    };
    const firstValue = model.getValueInRange({
      endColumn: position.column + 1,
      ...bracketOptions,
    });

    const secondValue = model.getValueInRange({
      endColumn: position.column + 2,
      ...bracketOptions,
    });
    const thirdValue = model.getValueInRange({
      endColumn: position.column + 3,
      ...bracketOptions,
    });

    if (firstValue !== '}') {
      return 0;
    }
    const hasThreeBrackets =
      firstValue === '}' && secondValue === '}}' && thirdValue === '}}';
    if (hasThreeBrackets) return 3;
    const hasTwoBrackets = firstValue === '}' && secondValue === '}}';
    if (hasTwoBrackets) return 2;
    if (firstValue === '}') return 1;
    return 0;
  };

  const nextBracketNumber = getNextBracketNumber();

  const prevBracketNumber = getPreviousBracketNumber();

  const prevChar = model.getValueInRange({
    startColumn: position.column - 1,
    startLineNumber: position.lineNumber,
    endColumn: position.column,
    endLineNumber: position.lineNumber,
  });
  // added nextChar and hasNextBracket. Changed hasBracket to hasPrevBracket
  const nextChar = model.getValueInRange({
    startColumn: position.column,
    startLineNumber: position.lineNumber,
    endColumn: position.column + 1,
    endLineNumber: position.lineNumber,
  });

  const hasNextBracket = nextChar === '}';
  const hasPrevBracket = prevChar === '{';

  const word = model.getWordUntilPosition(position);
  let text = word.word;

  const prev = model.findPreviousMatch(
    '{',
    {
      lineNumber: 0,
      column: 0,
    },
    false,
    false,
    null,
    true
  );

  if (prev) {
    text = model.getValueInRange({
      ...prev.range,
      endColumn: position.column,
    });
  }

  text = text.replace('{', '').trim();

  if (text.length === 0) {
    results = suggestions;
  } else {
    const splits = text.split('.').filter(s => s.trim() !== '');

    if (splits.length) {
      hasParent = true;

      let i = 0;
      let children = suggestions;
      const dotEnd = text[text.length - 1] === '.';

      for (const split of splits) {
        const found = children.find(c => c.insertText === split);
        const isLast = splits.length - 1 === i++;

        if (dotEnd) {
          if (found?.children?.length) {
            children = found.children;

            if (isLast) {
              results = children;
            }
          }
        } else {
          if (found) {
            if (isLast) {
              children = children.filter(s => s.insertText.includes(text));
            } else if (found.children) {
              children = found.children;
            }
          }
        }
      }

      results = children;
    } else {
      results = suggestions.filter(s => s.insertText.includes(text));
    }
  }

  // if (containsTripleBraces) {
  //   results = results.slice(4);
  // }

  return results.map(suggestion =>
    createSuggestion(
      instance,
      suggestion,
      hasPrevBracket,
      hasNextBracket,
      hasParent,
      prevBracketNumber,
      nextBracketNumber
    )
  );
};
