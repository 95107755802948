import moment from 'moment';
import Handlebars from 'handlebars';

const registerHelpers = Handlebars => {
  Handlebars.registerHelper('and', function(arg1, arg2, options) {
    return arg1 && arg2 ? options.fn(this) : options.inverse(this);
  });
  Handlebars.registerHelper('or', function(arg1, arg2, options) {
    return arg1 || arg2 ? options.fn(this) : options.inverse(this);
  });
  Handlebars.registerHelper('equals', function(arg1, arg2, options) {
    return arg1 == arg2 ? options.fn(this) : options.inverse(this);
  });
  Handlebars.registerHelper('notEquals', function(a, b, options) {
    if (a != b) {
      return options.fn(this);
    }
    return options.inverse(this);
  });
  Handlebars.registerHelper('lessThan', function(v1, v2, options) {
    if (v1 < v2) {
      return options.fn(this);
    }
    return options.inverse(this);
  });
  Handlebars.registerHelper('greaterThan', function(v1, v2, options) {
    if (v1 > v2) {
      return options.fn(this);
    }
    return options.inverse(this);
  });
  Handlebars.registerHelper('length', function(v1, options) {
    options.name = 'no-hash';
    return v1.length;
  });
  // Handlebars.registerHelper('add', function(v1, v2, options) {
  //   // not a sendgrid helper - used to add 1 to an index within an each loop
  //   return parseInt(v1) + parseInt(v2);
  // });
  Handlebars.registerHelper('formatDate', function(
    timeStamp,
    dateFormat,
    timezoneOffset,
    options
  ) {
    if (timeStamp && typeof timeStamp === 'string') {
      if (typeof timezoneOffset === 'string') {
        return moment(timeStamp)
          .utcOffset(timezoneOffset)
          .format(dateFormat);
      }

      return moment(timeStamp).format(dateFormat);
    }
    return null;
  });
  // Handlebars.registerHelper('today', function() {
  //   return moment().format('YYYY-MM-DD');
  // });
  Handlebars.registerHelper('insert', function(value, defaultValue) {
    if (value) {
      return value;
    } else {
      const containsDefaultString = defaultValue.indexOf('default=');
      const split = defaultValue.split('default=');
      return containsDefaultString !== -1 ? split[1] : '';
    }
  });
  Handlebars.registerHelper('unless', function(value, options) {
    if (!value) {
      return options.fn(this);
    }
    return null;
  });
};
registerHelpers(Handlebars);
export default Handlebars;
