import React, { useEffect } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { tabContent } from './util/tabContent';
import './styles.scss';

const HandlebarsExamples = () => {
  useEffect(() => {
    // console.log('Prism', Prism);
    // Prism.highlightAll();
  }, []);

  return (
    <div>
      <div className="mb-3">
        <span>
          This feature utilizes the popular Handlebars template language. For
          more advanced documentation visit{' '}
          <a
            href="https://handlebarsjs.com/guide/#what-is-handlebars"
            target="_blank"
          >
            Handlebars
          </a>
          .
        </span>
      </div>
      <ul className="pl-3">
        {tabContent.map((content, index) => {
          return (
            <li key={`${content?.href}_jm`}>
              <a className="" href={`#${content?.href}`}>
                {content?.title}
              </a>
            </li>
          );
        })}
      </ul>
      {tabContent.map((content, index) => {
        const contentTitle = content?.title ? content?.title.toUpperCase() : '';
        return (
          <div
            key={`${content?.href}_container`}
            className="d-block handlebarsExample h-100 mb-4"
          >
            <h3 id={content?.href}>{contentTitle}</h3>
            <ul className="nav nav-pills d-flex justify-content-between">
              <li className="handlebars">
                <a data-toggle="pill" href={`#handlebars_${index}`}>
                  Handlebars
                </a>
              </li>
              <li>
                <a data-toggle="pill" href={`#json_${index}`}>
                  JSON
                </a>
              </li>
              <li>
                <a data-toggle="pill" href={`#html_${index}`}>
                  HTML
                </a>
              </li>
            </ul>

            <div className="tab-content">
              <div
                id={`handlebars_${index}`}
                className="tab-pane fade show active"
              >
                <SyntaxHighlighter language="handlebars" style={docco}>
                  {content?.handlebarsCodeString}
                </SyntaxHighlighter>
              </div>
              <div id={`json_${index}`} className="tab-pane fade">
                <SyntaxHighlighter language="json" style={docco}>
                  {content?.jsonCodeString}
                </SyntaxHighlighter>
              </div>
              <div id={`html_${index}`} className="tab-pane fade">
                <SyntaxHighlighter language="html" style={docco}>
                  {content?.htmlCodeString}
                </SyntaxHighlighter>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default HandlebarsExamples;
