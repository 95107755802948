import React, { useState } from 'react';
import { Formik } from 'formik';
import ThemedButton from 'src/components/Forms/components/ThemedButton';
import FormResponse from 'src/components/Forms/components/FormResponse';
import gql from 'graphql-tag';
import { withApollo } from 'react-apollo';
// import * as Yup from 'yup';
import Loadable from '@loadable/component';
import { formatDateWithSlashes } from 'src/util/dateHelper';
const DatePicker =
  typeof window !== 'undefined' &&
  Loadable(() => import('src/components/DatePicker'));

const MARK_WELCOME_AS_SENT = gql`
  mutation MarkGuestReservationWelcomeMessageSent(
    $reference: String!
    $welcomeMessageDateSent: Date
  ) {
    markGuestReservationWelcomeMessageSent(
      where: { reference: $reference }
      data: { welcomeMessageDateSent: $welcomeMessageDateSent }
    ) {
      id
    }
  }
`;

const WelcomeMessageSendForm = ({ client, reference, onSuccess }) => {
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  return (
    <Formik
      initialValues={{
        welcomeMessageDateSent: new Date(),
      }}
      onSubmit={async (values, { setSubmitting, setStatus, setErrors }) => {
        const { welcomeMessageDateSent } = values;

        try {
          const { data, loading } = await client.mutate({
            mutation: MARK_WELCOME_AS_SENT,
            variables: {
              reference,
              welcomeMessageDateSent,
            },
          });

          if (data) {
            onSuccess();
            setStatus({ success: true });
          }
        } catch (err) {
          console.log('err', err);
          setStatus({ error: err });
        }
        setSubmitting(false);
      }}
    >
      {formikProps => {
        const {
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          submitCount,
          status,
        } = formikProps;
        return (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label className="mb-1">Date</label>
              <div>
                <button
                  style={{ minHeight: '38px' }}
                  type="button"
                  className="btn w-100 text-left border mb-2"
                  onClick={() => {
                    setDatePickerOpen(prevState => !prevState.datePickerOpen);
                  }}
                >
                  {values.welcomeMessageDateSent !== ''
                    ? formatDateWithSlashes(values.welcomeMessageDateSent)
                    : ''}
                </button>
                {datePickerOpen && (
                  <DatePicker
                    allowSameDay={true}
                    onClickOutside={() => setDatePickerOpen(false)}
                    selectsStart
                    startDate={values.welcomeMessageDateSent}
                    selected={values.welcomeMessageDateSent}
                    name="welcomeMessageDateSent"
                    type="text"
                    className="form-control rounded"
                    id="welcomeMessageDateSent"
                    withPortal
                    inline
                    maxDate={new Date()}
                    onChange={args => {
                      setFieldValue('welcomeMessageDateSent', args);
                      setDatePickerOpen(false);
                    }}
                  />
                )}
              </div>
              <div className="text-danger">
                {touched.welcomeMessageDateSent &&
                  errors.welcomeMessageDateSent &&
                  errors.welcomeMessageDateSent}
              </div>
            </div>

            <FormResponse
              errors={status?.error}
              alert={Object.keys(errors).length > 0 && submitCount > 0}
              isVisible={
                status?.error ||
                status?.success ||
                (Object.keys(errors).length > 0 && submitCount > 0)
              }
            >
              Your Message has been marked as sent!
            </FormResponse>
            <ThemedButton
              type="submit"
              loading={isSubmitting}
              className="w-100 mb-2 btn btn-primary"
            >
              Submit
            </ThemedButton>
          </form>
        );
      }}
    </Formik>
  );
};

export default withApollo(WelcomeMessageSendForm);
