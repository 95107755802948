import React, { useState, useEffect, useRef } from 'react';
import WelcomeMessageSendForm from './components/WelcomeMessageSendForm';
import LoadingSpinner from 'src/components/LoadingSpinner';
import TabbedDrawer from 'src/components/TabbedDrawer';
import Handlebars from 'src/util/handlebarsHelpers';
import debounce from 'src/util/debounce';
import useUser from 'src/contexts/useUser';
import gql from 'graphql-tag';
import Query from 'src/components/GraphQL/components/Query';
import { Mutation, withApollo } from 'react-apollo';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Tooltip from 'src/components/Tooltip';
import ThemedButton from 'src/components/Forms/components/ThemedButton';
import HandlebarsExampleTabs from './components/HandlebarsExampleTabs';
import { AlertTriangle } from 'react-feather';
import Modal from 'src/components/Modal';
import TimedResponseMessage from 'src/components/TimedResponseMessage';
import 'react-tabs/style/react-tabs.css';
import 'graphiql/graphiql.css';
import moment from 'moment';

import './styles.scss';

/*
Combining 100% height and flex box (using absolute positioning)
https://www.codementor.io/@stephenbunch/how-to-make-a-scrollable-container-with-dynamic-height-using-flexbox-bkaunxewg
*/
import {
  buildSuggestions,
  // createSuggestion,
} from './util/autocompleteProvider';

// https://www.npmjs.com/package/graphiql/v/1.3.2

const handlebarsHelperDefinitions = [
  {
    key: 'if',
    description:
      'Conditionally render a block. If its argument returns false, undefined, null, "", 0, or [], Handlebars will not render the block.',
  },
  {
    key: 'else',
    description:
      "If included within an 'if' block helper, it will render content if the parent condition proves false.",
  },
  {
    key: 'unless',
    description:
      'The inverse of the if helper. Its block will be rendered if the expression returns a falsy value.',
  },
  {
    key: 'each',
    description:
      "Iterate over a list. Use 'this' to reference the element being iterated over.",
  },
  {
    key: 'with',
    description:
      'Allows you to change the evaluation context of template-part.',
  },
  {
    key: 'lookup',
    description:
      'Allows for dynamic parameter resolution using Handlebars variables.',
  },
  {
    key: 'log',
    description:
      'Allows for logging of context state while executing a template.',
  },
  // {
  //   key: 'blockHelperMissing',
  //   description:
  //     'Called when a block-expression calls a helper that is not registered, but the name matches a property in the current evaluation context.',
  // },
  // {
  //   key: 'helperMissing',
  //   description:
  //     'Called when a mustache or a block-statement is not a registered helper AND is not a property of the current evaluation context.',
  // },
  {
    key: 'formatDate',
    description:
      'Takes a time in either epoch or ISO8601 format and converts it to a format you specify.',
  },
  {
    key: 'greaterThan',
    description: 'Proves true if first value is greater than the second value.',
  },
  {
    key: 'lessThan',
    description: 'Proves true if first value is less than the second value.',
  },
  {
    key: 'equals',
    description: 'Proves true if first value is equal to the second value.',
  },
  {
    key: 'notEquals',
    description: 'Proves true if first value not equal to the second value.',
  },
  {
    key: 'and',
    description: 'Proves true if first and second values are truthy.',
  },
  {
    key: 'or',
    description: 'Proves true if first or second values are truthy.',
  },
  {
    key: 'length',
    description: 'Returns the number of characters in a given string or array.',
  },
  {
    key: 'insert',
    description: 'Returns the value of the argument provided',
  },
];

const MARK_WELCOME_AS_SENT = gql`
  mutation MarkGuestReservationWelcomeMessageSent($reference: String!) {
    markGuestReservationWelcomeMessageSent(where: { reference: $reference }) {
      id
    }
  }
`;

const MARK_WELCOME_AS_UNSENT = gql`
  mutation MarkGuestReservationWelcomeMessageUnsent($reference: String!) {
    markGuestReservationWelcomeMessageUnsent(where: { reference: $reference }) {
      id
    }
  }
`;

const UPDATE_TEMPLATE_BUILDER_DEFINITION = gql`
  mutation updateTemplateBuilderDefinition(
    $TemplateBuilderDefinitionUpdateInput: TemplateBuilderDefinitionUpdateInput
    $TemplateBuilderDefinitionWhereUniqueInput: TemplateBuilderDefinitionWhereUniqueInput
  ) {
    updateTemplateBuilderDefinition(
      where: $TemplateBuilderDefinitionWhereUniqueInput
      data: $TemplateBuilderDefinitionUpdateInput
    ) {
      body
    }
  }
`;

const NOTIFICATION_DEFINITION = gql`
  query TemplateBuilderDefinition($id: Int, $params: String) {
    templateBuilderDefinition(where: { id: $id }) {
      type
      sqlStatement
      body
      templateContext(params: $params) {
        data
        inputFields {
          type
          name
          value
          ... on TemplateBuilderSelectField {
            options {
              label
              value
            }
          }
        }
      }
    }

    templateBuilderRevisions(
      first: 50
      where: { templateBuilderDefinitionId: $id }
    ) {
      createdAt
      body
    }
  }
`;

const TemplateBuilder = ({
  definitionId,
  params,
  client,
  contextData,
  reference,
  onSuccess,
  welcomeSent,
}) => {
  const {
    templateBody,
    templateData,
    inputFields,
    definitionRevisions,
  } = contextData?.state;

  const {
    setTemplateBody,
    setTemplateData,
    setInputFields,
    setDefinitionRevisions,
  } = contextData;
  const urlParams = new URLSearchParams(location?.search);

  const disposables = useRef([]);
  const monacoContainer = useRef(null);
  const templateTextarea = useRef(null);
  const clipboardButton = useRef(null);
  const [bodyError, setBodyError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mutationFeedback, setMutationFeedback] = useState(false);
  const [clipboardModalIsOpen, setClipboardModalIsOpen] = useState(false);
  const [welcomeSentMutationLoading, setWelcomeSentMutationLoading] = useState(
    false
  );
  const [markAsSentModalIsOpen, setMarkAsSentModalIsOpen] = useState(false);
  const [welcomeSentLabel, setWelcomeSentLabel] = useState(
    welcomeSent ? 'Mark as Unsent' : 'Mark as Sent'
  );
  const [alertShowTimeout, setAlertShowTimeout] = useState(false);

  const Textarea = ({ compiledData }) => {
    const templateTextarea = useRef(null);
    const toggleDisabledClass = () => {
      const scrollTop = templateTextarea.current.scrollTop;
      const innerHeight = $(templateTextarea.current).innerHeight();
      const scrollHeight = templateTextarea.current.scrollHeight;

      if (scrollTop + innerHeight + 2 >= scrollHeight) {
        $(clipboardButton.current).removeClass('clipboard-button-disabled');
      } else {
        // $(clipboardButton.current).addClass('clipboard-button-disabled');
      }
    };
    const handleTextareaScroll = e => {
      toggleDisabledClass();
    };

    useEffect(() => {
      templateTextarea.current &&
        templateTextarea.current.addEventListener(
          'scroll',
          handleTextareaScroll
        );
      toggleDisabledClass();

      return () => {
        templateTextarea.current &&
          templateTextarea.current.removeEventListener(
            'scroll',
            handleTextareaScroll
          );
      };
    }, []);
    return (
      <textarea
        hidden={!definitionId}
        ref={templateTextarea}
        className={`form-control`}
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
        }}
        value={compiledData}
        onChange={() => {}}
      />
    );
  };

  const getCompiledHandlebarsTemplate = (templateCode, data) => {
    try {
      const template = Handlebars.compile(templateCode); //
      const templateResult = template(data);
      return templateResult;
    } catch (err) {
      console.log('err', err);
      setBodyError(err?.message);
      console.log('message', err?.message);
      return false;
    }
  };

  const initializeMonacoEditor = updatedTemplateBody => {
    if (!templateData || !updatedTemplateBody) return;

    // const getTemplateBodyEditorValue = () => {
    //   if (updatedTemplateBody) {
    //     return updatedTemplateBody;
    //   }
    //   return templateBody || '';
    // };
    // const templateBodyEditorValue = getTemplateBodyEditorValue();

    const needsHash = key => {
      if (
        key === 'length' ||
        key === 'insert' ||
        key === 'formatDate'
        // ||
        // key === 'today'
      ) {
        return false;
      }
      return true;
    };
    const editorOptions = {
      mode: { name: 'handlebars', base: 'text/html' },
      minimap: {
        enabled: false,
      },
      wordWrap: 'on',
      lineNumbers: true,
      automaticLayout: true,
    };
    window.monaco_loader.init().then(monaco => {
      const properties = {
        value: updatedTemplateBody || '',
        language: 'handlebars',
        ...editorOptions,
      };

      const editor = monaco.editor.create(monacoContainer?.current, properties);

      editor.onDidChangeModelContent(event => {
        // const value = model.getValue();
        const value = editor?.getValue();

        const debounced = debounce(function() {
          setTemplateBody(value);
          const transformedContentFromTemplate = getCompiledHandlebarsTemplate(
            value,
            templateData
          );
          if (transformedContentFromTemplate) {
            // setTransformedBodyContent(transformedContentFromTemplate);
            setBodyError(null);
          }
        }, 500);
        debounced();
      });

      const provider = monaco.languages.registerCompletionItemProvider(
        'handlebars',
        {
          triggerCharacters: '{',
          provideCompletionItems: (model, position, context) => {
            const futureRangeValues = model.getValueInRange({
              startLineNumber: position.lineNumber,
              startColumn: position.column,
              endLineNumber: position.lineNumber,
              endColumn: position.column + 4,
            });
            const pastRangeValues = model.getValueInRange({
              startLineNumber: position.lineNumber,
              startColumn: position.column,
              endLineNumber: position.lineNumber,
              endColumn: position.column - 3,
            });

            const containsFutureTripleBraces =
              futureRangeValues.indexOf('}}}') === 1 ? true : false;
            const containsPastTripleBraces =
              pastRangeValues.indexOf('{{{') === 0 ? true : false;
            const containsTripleBraces =
              containsFutureTripleBraces || containsPastTripleBraces;

            const propSuggestions = Object.keys(templateData).reduce(
              (acc, data) => {
                acc.push({
                  label: data,
                  kind: monaco.languages.CompletionItemKind.Variable,
                  insertText: data,
                  insertTextRules:
                    monaco.languages.CompletionItemInsertTextRule
                      .InsertAsSnippet,
                  detail: String(templateData[data]),
                });
                return acc;
              },
              []
            );

            const handlebarsHelperSuggestions = Object.keys(
              Handlebars?.helpers
            ).reduce((acc, data) => {
              const helperMatch = handlebarsHelperDefinitions.find(
                definition => data === definition?.key
              );
              if (data === 'blockHelperMissing' || data === 'helperMissing') {
                return acc;
              }
              if (containsTripleBraces && needsHash(data)) {
                return acc;
              }
              acc.push({
                label: needsHash(data) ? `#${data}` : data,
                kind: monaco.languages.CompletionItemKind.Function,
                insertText: needsHash(data) ? `#${data}` : data,
                insertTextRules:
                  monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                documentation: helperMatch?.description,
              });
              return acc;
            }, []);
            if (!containsTripleBraces) {
              handlebarsHelperSuggestions.push(
                {
                  label: 'else',
                  kind: monaco.languages.CompletionItemKind.Function,
                  insertText: 'else',
                  insertTextRules:
                    monaco.languages.CompletionItemInsertTextRule
                      .InsertAsSnippet,
                  documentation:
                    "If included within an 'if' block helper, it will render content when the parent condition proves false.",
                },
                {
                  label: 'else if',
                  kind: monaco.languages.CompletionItemKind.Function,
                  insertText: 'else if',
                  insertTextRules:
                    monaco.languages.CompletionItemInsertTextRule
                      .InsertAsSnippet,
                  documentation:
                    "If included within an 'if' block helper, it will render content when the parent condition proves false and it's argument proves true.",
                }
              );
            }
            const suggestions = buildSuggestions(
              monaco,

              [...propSuggestions, ...handlebarsHelperSuggestions],
              model,
              position
            );
            return {
              suggestions,
            };
          },
        }
      );

      disposables.current.push(provider);
    });
  };
  const getTemplateData = (updatedTemplateBody, user) => {
    setIsLoading(true);
    client
      .query({
        query: NOTIFICATION_DEFINITION,
        variables: {
          id: Number(definitionId),
          params,
        },
        fetchPolicy: 'no-cache',
      })
      .then(({ data }) => {
        if (data) {
          setTemplateData({
            ...data?.templateBuilderDefinition?.templateContext?.data,
            gUser: user,
          });
          setTemplateBody(
            updatedTemplateBody || data?.templateBuilderDefinition?.body
          );
          setInputFields(
            data?.templateBuilderDefinition?.templateContext?.inputFields
          );
          setDefinitionRevisions(data?.templateBuilderRevisions);
          setIsLoading(false);
          return data;
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  const user = useUser();

  useEffect(() => {
    !templateData && getTemplateData(null, user);

    if (templateData && templateBody) {
      initializeMonacoEditor(templateBody);
    }

    return () => {
      //cachedEditor && cachedEditor.dispose();
      disposables.current.forEach(disposable => {
        disposable.dispose();
      });
    };
  }, [templateData, templateBody, definitionId]);
  const editorOptions = {
    mode: { name: 'handlebars', base: 'text/html' },
    minimap: {
      enabled: false,
    },
    wordWrap: 'on',
    lineNumbers: true,
  };

  const tabsData = [
    {
      name: 'Docs',
      content: <HandlebarsExampleTabs />,
    },
    {
      name: 'Data',
      content: inputFields && (
        <div>
          {inputFields.map(field => {
            const fieldName = field?.name;
            const defaultValue = field?.value;
            const fieldType = field?.type;
            const options = field?.options;

            const updateTemplate = e => {
              const keyValuePair = {};
              keyValuePair[fieldName] = e?.target?.value;
              setTemplateData({ ...templateData, ...keyValuePair });
            };

            const templateValue = templateData && templateData[fieldName];

            if (fieldType === 'INPUT') {
              return (
                <div key={fieldName} className="mb-2">
                  <label className="mb-1">{fieldName}</label>
                  <input
                    className="form-control"
                    defaultValue={templateValue || defaultValue}
                    onChange={e => {
                      e.persist();
                      const debounced = debounce(function() {
                        updateTemplate(e);
                      }, 500);
                      debounced();
                    }}
                  />
                </div>
              );
            }
            return (
              <div key={fieldName} className="mb-2">
                <label className="mb-1">{fieldName}</label>
                <select
                  className="form-control"
                  name="platformKey"
                  defaultValue={defaultValue}
                  onChange={e => {
                    updateTemplate(e);
                  }}
                >
                  <option hidden>Choose One</option>
                  {options?.map(option => {
                    return (
                      <option
                        // disabled={!platformIsSupported}
                        key={option?.value}
                        value={option?.value}
                      >
                        {option?.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            );
          })}
        </div>
      ),
    },
    {
      name: 'Revisions',
      content: (
        <div>
          <ul className="list-unstyled">
            {definitionRevisions?.map(definition => {
              return (
                <li>
                  <button
                    className="btn btn-link mb-0"
                    onClick={() => {
                      disposables.current.forEach(disposable => {
                        disposable.dispose();
                      });
                      setTemplateBody(definition?.body);
                      getTemplateData(definition?.body, user);
                    }}
                  >
                    {moment(definition?.createdAt).format('LLLL')}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      ),
    },
  ];

  return (
    <Mutation
      mutation={UPDATE_TEMPLATE_BUILDER_DEFINITION}
      variables={{ id: Number(definitionId), body: templateBody }}
    >
      {(updateDefinition, { data, loading: mutationLoading }) => {
        // if (isLoading)
        //   return (
        //     <div
        //       className="d-flex justify-content-center align-items-center"
        //       style={{ height: '80vh' }}
        //     >
        //       <LoadingSpinner />
        //     </div>
        //   );

        const compiledData = getCompiledHandlebarsTemplate(
          templateBody || '',
          templateData || {}
        );

        return (
          // <HandlebarsProvider>
          <div id="template-builder" className="d-flex h-100 flex-column">
            <div
              className="d-flex flex-grow-1 position-relative"
              style={{ overflow: 'hidden' }}
            >
              {definitionId && <TabbedDrawer tabs={tabsData} />}
              {isLoading ? (
                <div
                  className="d-flex justify-content-center align-items-center w-100"
                  style={{ height: '70vh' }}
                >
                  <LoadingSpinner />
                </div>
              ) : (
                <>
                  {' '}
                  <div id="monaco-template-wrapper" className="w-50 h-100">
                    <div
                      className="w-100 h-100"
                      ref={monacoContainer}
                      id="root"
                      // style={{ height: '100vh' }}
                    ></div>
                  </div>
                  <div className="w-50  position-relative">
                    {bodyError && (
                      <div
                        className="position-absolute"
                        style={{ right: '20px', top: '10px' }}
                      >
                        {/* {bodyError}{' '} */}
                        <Tooltip
                          label={bodyError}
                          // label={'testing one two three four'}
                          placement="left"
                          id={`calendar-tooltip-template`}
                          // hide={!bodyError}
                          // className="d-none"
                          // className="position-absolute"
                          style={{ maxWidth: '500px' }}
                        >
                          <AlertTriangle
                            width={30}
                            height={30}
                            className="alert-triangle-200"
                            // style={{ right: '40px', top: '10px' }}
                            // className={`d-flex align-items-center alert-triangle-300`}
                          />
                        </Tooltip>
                      </div>
                    )}
                    {/* <textarea
                  ref={templateTextarea}
                  className="form-control"
                  style={{
                    width: '100%',
                    height: '100%',
                    border: 'none',
                  }}
                  value={compiledData}
                  onChange={() => {}}
                /> */}
                    {compiledData && <Textarea compiledData={compiledData} />}
                  </div>
                </>
              )}

              <div
                className={`alert alert-warning alert-dismissible position-absolute ${
                  alertShowTimeout ? 'fade-out' : 'd-none'
                }`}
                role="alert"
                style={{
                  right: '30px',
                  bottom: '0px',
                  minWidth: '200px',
                }}
              >
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  // onClick={() => {
                  //   setAlertShowTimeout(true);
                  //   setTimeout(() => {
                  //     setAlertShowTimeout(false);
                  //   }, 5000);
                  // }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                Copied to Clipboard!
              </div>
            </div>
            {definitionId && (
              <footer
                className="border-top d-flex justify-content-between"
                style={{
                  flexShrink: 0,
                  // display: 'flex justify-content-between',
                  // justifyContent: 'flex-end',
                  padding: '5px',
                }}
              >
                {/* <button
                onClick={async () => {
                  const response = confirm(
                    `Are you sure? This will permanently save your template updates to the database. Note that you can modify the template specific to your needs and copy the output without updating the template in the database.`
                  );
                  if (response) {
                    const result = await updateDefinition({
                      id: Number(definitionId),
                      body: templateBody,
                      variables: {
                        TemplateBuilderDefinitionUpdateInput: {
                          body: templateBody,
                        },
                        TemplateBuilderDefinitionWhereUniqueInput: {
                          id: Number(definitionId),
                        },
                      },
                    });
                    console.log('result', result);
                  }
                }}
                className="btn btn-primary"
              >
                Update Template
              </button> */}
                <ThemedButton
                  onSuccess={mutationFeedback?.success}
                  onClick={async () => {
                    const response = confirm(
                      `Are you sure? This will permanently save your template updates to the database. Note that you can modify the template specific to your needs and copy the output without updating the template in the database.`
                    );

                    if (response) {
                      if (mutationFeedback?.success)
                        setMutationFeedback({ success: false });
                      try {
                        const { data } = await updateDefinition({
                          id: Number(definitionId),
                          body: templateBody,
                          variables: {
                            TemplateBuilderDefinitionUpdateInput: {
                              body: templateBody,
                            },
                            TemplateBuilderDefinitionWhereUniqueInput: {
                              id: Number(definitionId),
                            },
                          },
                        });
                        if (data) {
                          setMutationFeedback({
                            success: true,
                            failure: false,
                          });
                        }
                      } catch (err) {
                        setMutationFeedback({ failure: true, success: false });
                        alert(err);
                      }
                    }
                  }}
                  type="button"
                  loading={mutationLoading}
                  className="btn btn-outline-primary"
                >
                  Update Template
                </ThemedButton>
                <div className="d-flex align-items-center">
                  <div className="mr-2">
                    <span className="text-muted">
                      {compiledData?.length || 0} characters with spaces (cws)
                    </span>
                  </div>

                  <CopyToClipboard
                    text={compiledData}
                    //onCopy={() => this.setState({copied: true})}
                  >
                    <button
                      ref={clipboardButton}
                      // disabled={clipboardDisabled}
                      className={`clipboard-button-disabled btn btn-primary`}
                      onClick={e => {
                        const hasDisabledClass = $(
                          clipboardButton.current
                        ).hasClass('clipboard-button-disabled');

                        if (hasDisabledClass) {
                          alert(
                            'Please review the content thoroughly before copying content to clipboard.'
                          );
                        } else {
                          setAlertShowTimeout(true);
                          setTimeout(() => {
                            setAlertShowTimeout(false);
                          }, 9000);
                        }
                      }}
                    >
                      Copy to Clipboard
                    </button>
                  </CopyToClipboard>

                  <button
                    onClick={async () => {
                      if (welcomeSentLabel === 'Mark as Unsent') {
                        try {
                          const { data, loading } = await client.mutate({
                            mutation: MARK_WELCOME_AS_UNSENT,
                            variables: {
                              reference:
                                reference || urlParams.get('reference'),
                            },
                          });

                          setWelcomeSentMutationLoading(true);

                          if (data) {
                            setWelcomeSentLabel('Mark Sent');
                            setWelcomeSentMutationLoading(false);
                            alert('Your welcome message was marked as unsent!');
                            onSuccess();
                          }
                        } catch (err) {
                          console.log('err', err);
                          setWelcomeSentMutationLoading(false);
                          alert(
                            'There was a problem with updating the guest reservation. Please try again'
                          );
                        }
                        return;
                      }
                      setMarkAsSentModalIsOpen(true);
                      // try {
                      //   const { data, loading } = await client.mutate({
                      //     mutation: MARK_WELCOME_AS_SENT,
                      //     variables: {
                      //       reference: reference || urlParams.get('reference'),
                      //     },
                      //   });

                      //   setWelcomeSentMutationLoading(true);

                      //   if (data) {
                      //     setWelcomeSentLabel('Mark as Unsent');
                      //     setWelcomeSentMutationLoading(false);
                      //     // alert('Your welcome message was successfully sent!');
                      //     onSuccess();
                      //   }
                      // } catch (err) {
                      //   console.log('err', err);
                      //   setWelcomeSentMutationLoading(false);
                      //   alert(
                      //     'There was a problem with updating the guest reservation. Please try again'
                      //   );
                      // }
                    }}
                    className="btn btn-outline-primary ml-2"
                  >
                    {welcomeSentMutationLoading ? (
                      <div style={{ height: '20px' }}>
                        <LoadingSpinner
                          customDimensions={{ height: '20px', width: '20px' }}
                        />
                      </div>
                    ) : (
                      <> {welcomeSentLabel}</>
                    )}
                  </button>
                  {/* <TimedResponseMessage
                    text={'Copied to Clipboard!'}
                    timeout={4000}
                  /> */}
                </div>
              </footer>
            )}
            <Modal
              // large={true}
              isOpen={markAsSentModalIsOpen}
              title="Welcome Message Send Date"
              styleType="medium"
              disableForceFocus={true}
              closeOnOverlayClick={false}
              closeOnEsc={false}
              onClose={() => {
                setMarkAsSentModalIsOpen(false);
              }}
            >
              <WelcomeMessageSendForm
                reference={reference || urlParams.get('reference')}
                onSuccess={() => {
                  setWelcomeSentLabel('Mark as Unsent');
                  onSuccess();
                }}
              />
            </Modal>
            <Modal
              isOpen={clipboardModalIsOpen}
              title="Copied to Clipboard!"
              // styleType="full"
              disableForceFocus={true}
              closeOnOverlayClick={false}
              closeOnEsc={false}
              onClose={() => {
                setClipboardModalIsOpen(false);
              }}
            >
              <div>
                <p>
                  Send the message to the guest and then click the button below.
                </p>
                <button
                  className="btn btn-primary"
                  onClick={async () => {
                    try {
                      const { data, loading } = await client.mutate({
                        mutation: MARK_WELCOME_AS_SENT,
                        variables: {
                          reference,
                        },
                      });

                      setWelcomeSentMutationLoading(true);
                      if (data) {
                        setWelcomeSentMutationLoading(false);
                        alert('Your welcome message was successfully sent!');
                        onSuccess();
                      }
                    } catch (err) {
                      console.log('err', err);
                      setWelcomeSentMutationLoading(true);
                      alert(
                        'There was a problem with updating the guest reservation. Please try again'
                      );
                    }
                  }}
                >
                  {welcomeSentMutationLoading ? (
                    <LoadingSpinner />
                  ) : (
                    'I sent the message to the guest!'
                  )}
                </button>
              </div>
            </Modal>
          </div>
          // </HandlebarsProvider>
        );
      }}
    </Mutation>
  );
};

TemplateBuilder.defaultProps = {
  onSuccess: () => {},
};

export default withApollo(TemplateBuilder);
