import { useContext } from 'react';
import { UserContext } from './User';

export default function useUser() {
  const { state } = useContext(UserContext);

  if (!state) {
    throw new Error('useUser must be used within the UserContext provider');
  }

  return state.userData;
}
