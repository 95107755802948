import React, { useEffect, useState } from 'react';
import 'react-responsive-modal/styles.css';
import ResponsiveModal from 'react-responsive-modal';
import styles from './styles.module.scss';
const getModalStyle = styleType => {
  if (styleType === 'medium') return styles.modalMd;
  if (styleType === 'medium2') return styles.modalMd2;
  if (styleType === 'large') return styles.modalLg;
  if (styleType === 'full') return styles.modalFull;
  return styles.modal;
};
const getOverlayStyle = styleType => {
  // if (styleType === 'large') return styles.overlayLg;
  // if (styleType === 'full') return styles.overlayFull;
  return styles.overlay;
};
const getRootStyle = styleType => {
  // if (styleType === 'large') return styles.overlayLg;
  if (styleType === 'full') return styles.rootFull;
  return styles.root;
};
const Modal = ({
  render,
  title,
  isOpen,
  onClose,
  children,
  footerContent,
  removeHeaderBorder,
  large,
  styleType,
  disableForceFocus,
  customHeader,
  closeOnOverlayClick,
  closeOnEsc,
  scrollable,
  customBodyStyles,
  ...rest
}) => {
  return (
    <ResponsiveModal
      className="modal-content"
      open={isOpen}
      onClose={() => onClose()}
      center
      showCloseIcon={false}
      closeOnEsc={closeOnEsc}
      closeOnOverlayClick={closeOnOverlayClick}
      classNames={{
        modal: getModalStyle(styleType), //large ? styles.modalLg : styles.modal,
        overlay: getOverlayStyle(styleType),
        root: getRootStyle(styleType),
      }}
    >
      <>
        <div
          className={`modal-header position-relative ${
            removeHeaderBorder ? 'border-0' : ''
          }`}
        >
          {customHeader && customHeader()}
          {!customHeader && (
            <h5 className="modal-title w-100 font-weight-bold">{title}</h5>
          )}
          <button
            tabIndex={disableForceFocus ? '0' : '-1'}
            className="btn close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => onClose()}
            style={{ padding: '0.8rem' }}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div
          id={scrollable ? 'scrollable-body' : ''}
          className={`modal-body ${customBodyStyles}`}
        >
          {children}
        </div>
        {footerContent && <div className="modal-footer">{footerContent}</div>}
      </>
    </ResponsiveModal>
  );
};

Modal.defaultProps = {
  isOpen: false,
  title: 'modal',
  onClose: () => {},
  footerContent: null,
  removeHeaderBorder: false,
  large: false,
  disableForceFocus: false,
  customHeader: null,
};

export default Modal;
