import moment from 'moment';

export const getNumberOfDaysInRange = (startDate, endDate, params) => {
  const start = moment(startDate).utc();
  const end = moment(endDate).utc();

  return (
    moment
      .utc(end.format('YYYYMMDD'))
      .diff(moment.utc(start.format('YYYYMMDD')), 'days') + 1
  );
};

export var getDaysInMonth = function(month, year) {
  // Here January is 0 based
  return new Date(year, month + 1, 0).getDate();
};

export const getAllMonthsWithinRange = (startDate, endDate) => {
  var start = startDate.split('/');
  var end = endDate.split('/');
  var startYear = parseInt(start[0]);
  var endYear = parseInt(end[0]);
  var dates = [];

  for (var i = startYear; i <= endYear; i++) {
    var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
    var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
    for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      var month = j + 1;
      var displayMonth = month < 10 ? '0' + month : month;
      dates.push([i, displayMonth, '01'].join('/'));
    }
  }
  return dates;
};

export const formatDateWithSlashes = date => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [month, day, year].join('/');
};

export const getDatesInMonth = (month, year) => {
  var date = new Date(year, month, 1);
  var days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
};

export const formatDateWithDashes = date => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const addDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const getDateRange = (startDate, numDays) => {
  var arr = new Array(),
    dt = new Date(startDate),
    end = new Date(startDate).setDate(dt.getDate() + numDays);

  while (dt < end) {
    arr.push(new Date(dt));
    dt.setDate(dt.getDate() + 1);
  }

  return arr;
};

export const differenceBetweenDays = (start, end) => {
  var date1 = new Date(start);
  var date2 = new Date(end);
  // To calculate the time difference of two dates
  var Difference_In_Time = date2.getTime() - date1.getTime();
  // To calculate the no. of days between two dates
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  return Difference_In_Days < 0 ? 0 : Difference_In_Days;
};

export var getDatesInRange = function(startDate, endDate) {
  var dates = [],
    currentDate = startDate,
    addDays = function(days) {
      var date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
  while (currentDate <= endDate) {
    dates.push(formatDateWithDashes(currentDate));
    currentDate = addDays.call(currentDate, 1);
  }
  return dates;
};
export var dayNames = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

export var monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

/*the below function is necessary when dealing with date stings in the 'dashes' format with the year first (YYYY-MM-DD).
Converting this format to a date object sometimes casues the date to be off by one day. 
Changing the string to slashes removes this problem*/

export const parseDateStringDashesToSlashes = dateString => {
  return dateString ? dateString.replace(/-/g, '/').replace(/T.+/, '') : '';
};
export const decimalToDateTime = decimalTimeString => {
  //   var decimalTimeString = "1.6578";
  var n = new Date(0, 0);
  n.setSeconds(+decimalTimeString * 60 * 60);
  //   return n.toTimeString().slice(0, 8);
  return n;
};
