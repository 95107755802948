import React from 'react';
// import { CheckCircle, XCircle, AlertTriangle } from 'react-feather';

import alertSuccess from '../../../../images/alert-success.svg';
import alertError from '../../../../images/alert-error.svg';
import alertInfo from '../../../../images/alert-info.svg';
import styles from './styles.module.scss';

const getErrors = (errors = {}) => {
  if (errors === null) {
    return null;
  }
  if (errors.form) {
    errors = errors.form;
  }
  if (Object.entries(errors).length === 0 && errors.constructor === Object) {
    return null;
  }
  if (Array.isArray(errors.graphQLErrors)) {
    if (errors.graphQLErrors.length > 0) {
      return errors.graphQLErrors[0].message;
    }
  }
  if (Array.isArray(errors)) {
    // return errors[0].message;
    return errors.map(error => (
      <>
        <span>{error}</span>
        <br />
      </>
    ));
  }
  return (
    <span>There was a problem with your submission. Please try again.</span>
  );
};
const FormResponse = ({
  formikProps = {},
  errors,
  isVisible,
  children,
  alert,
  onClose,
}) => {
  const isOpen =
    ((formikProps.status || {}).isSubmitted && !formikProps.isSubmitting) ||
    isVisible ||
    false;
  //    const isVisible = (formikProps.status || {}).isSubmitted || formikProps.isSubmitting || false;
  const errorsFormatted = getErrors(errors);

  const color = errorsFormatted ? 'danger' : 'success';
  const message = errorsFormatted ? errorsFormatted : children;
  const getFeedbackIcon = () => {
    if (errorsFormatted) {
      return <img src={alertError} />;
    } else if (alert) {
      return <img src={alertInfo} className={`mr-1 ${styles.alertTriangle}`} />;
    }

    return <img src={alertSuccess} />;
  };
  const getAlertColor = () => {
    if (errorsFormatted) {
      return 'danger';
    } else if (alert) {
      return 'warning';
    }
    return 'success';
  };

  const getFeedbackMessaging = () => {
    if (errorsFormatted) {
      return (
        <span className={`${styles.feedbackText}`}>
          <b className="mr-1">Failed:</b>
          {errorsFormatted}
        </span>
      );
    } else if (alert) {
      return (
        <span className={`${styles.feedbackText}`}>
          <b className="mr-1">Info:</b>Please confirm the fields and submit
          again.
        </span>
      );
    }
    return (
      <span className={`${styles.feedbackText}`}>
        <b className="mr-1">Success:</b>
        {children === true ? 'Your info was submitted.' : children}
      </span>
    );
  };
  const getMessage = () => {
    if (errorsFormatted) {
      return 'Failed:';
    } else if (alert) {
      return 'Info:';
    }
    return 'Success:';
  };
  return (
    isOpen &&
    getMessage() && (
      <div
        className={`w-100 alert alert-${getAlertColor()} d-flex alert-dismissible d-flex align-items-center`}
        role="alert"
      >
        <span className="mr-1">
          {getFeedbackIcon()}
          {/* {errorsFormatted ? <XCircle /> : <CheckCircle />} */}
        </span>
        {/* <span> */}
        {/* <b className="mr-1">{errorsFormatted ? 'Failed:' : 'Success:'}</b> */}
        {getFeedbackMessaging()}
        {/* <button
          onClick={() => onClose()}
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button> */}
        {/* </span> */}
      </div>
    )
  );
};

FormResponse.defaultProps = {
  onClose: () => {},
  warningMessage: 'Please confirm the fields and submit again.',
};

export default FormResponse;
